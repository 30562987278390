export const environment = {
    production: false,
    PAYMENT_UI: 'https://epms-dev.ets.org',
    GRE_PAYPAL_CLIENTID: 'AfD8VCvzRraB3fFq11JjaY6tabxhvc3AQzly5rTBoZsWiPo5nzVQeglryZKRup6S2REmsshK3fotzKUN',
    PROGRAMS_PAYPAL_CLIENTID: 'ASmWyjwTKKtT2gpiOUnZ4hOqUBrE_jrQelsvUbNvEKZ5a-yZAKowhBD5dCoxk_iiQnBrw12dWheg4n5K',
    PRAXIS_PAYPAL_CLIENTID: 'AcSOgwQLwgkmRWeMNuS5qK9KYGfxwHR2sHu-gIxk72DsRkxR1zbbJrXM3wvXIQdrRWQlinL9aHwUrZg1',
    TOEFL_PAYPAL_CLIENTID: 'AcSOgwQLwgkmRWeMNuS5qK9KYGfxwHR2sHu-gIxk72DsRkxR1zbbJrXM3wvXIQdrRWQlinL9aHwUrZg1',
    MAX_PAYMENT_ALLOWED_ATTEMPTS: 3,
    PAYPAL_SDK: 'https://www.paypal.com/sdk/js?components=messages,buttons&disable-funding=card&client-id=',
    VERTUAL_TERIMINAL_URL: 'https://epmsadmin-dev-ui.ets.org/virtual-terminal',
    GTM_APP_ID: 'GTM-MGNHGKX'
};
