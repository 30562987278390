<header class="ets-header">
  <div class="navbar navbar-dark navbar-expanded ets-main-banner">
      <div class="container-fluid">
          <div></div>
          <div *ngIf="!oscCodes.includes(spcOscCode)" class="navbar-brand d-flex align-items-center center">
              <img class="navbar-logo" alt="E T S Logo" src="../../assets/img/ETS_header_logo.svg" />
          </div>
          <div *ngIf="oscCodes.includes(spcOscCode)" class="navbar-brand d-flex align-items-center center">
            <img class="navbar-logo" alt="TOEFL Logo" src="../../assets/svg/TOEFL.svg"/>
        </div>
      </div>
  </div>
</header>
